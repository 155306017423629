<template>
<div>
    <apexchart
     width="900" type="line"
     :options="options" :series="series" />
</div>
</template>
<script>

import { getConsultingTimesStats } from '@/api/consulting.api'
import crudMixin from '@/api/crud'

export default {
    mixins: [crudMixin],
    data() {
        return {
            team_hours: [],
            my_hours: []
        }
    },
    mounted() {
        this.call(
            getConsultingTimesStats(),
            (res) => {
                this.team_hours.splice(0, this.team_hours.length)
                res.data.times.forEach(person_hours =>{
                    var mine = person_hours[this.$store.getters.user_id]
                    if(!mine) {
                        mine = 0
                    }
                    this.my_hours.push(mine)
                    this.team_hours.push(
                        Object.values(person_hours).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                    )
                })
            }
        )
    },
    computed: {
        time_periods() {
            var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            var today = new Date()
            for(var i=0;i<=today.getMonth();i++) {
                months = this.arrayRotate(months)
            }
            return months
        },
        options() {
            return {
                chart: {
                    id: 'hours-worked-chart',
                    toolbar: {
                        show: true,
                    },
                    zoom: {
                        enabled: true
                    }
                },
                xaxis: {
                    //type: 'datetime',
                    categories: this.time_periods
                },
                title: {
                    text: 'Hours Worked',
                    align: 'center',
                    style: {
                        fontSize: '18px'
                    }
                }
            }
        },
        series() {
            return [{
                name: 'Team Hours',
                data: this.team_hours.slice().reverse()
            }, {
                name: 'My Hours',
                data: this.my_hours.slice().reverse()
            }]
        }
    },
    methods: {
        arrayRotate(arr, reverse) {
            if (reverse) arr.unshift(arr.pop());
            else arr.push(arr.shift());
            return arr;
        }
    }
}
</script>