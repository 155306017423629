<template>
<div>
    <b-dropdown variant="primary" :text="'FY' + selectedYear" class="ml-3 childClass">
          <b-dropdown-item
            v-for="index in (lastYear - firstYear + 1)"
            :key="index"
            @click="getFiscalYear(firstYear + index - 1 )"
            :active="selectedYear == ( firstYear + index - 1 )">
            FY{{ firstYear + index - 1 }}
          </b-dropdown-item>
      </b-dropdown>
    <apexchart
    class="parentClass"
    width="900" type="bar"
    :options="options" :series="series" />
</div>
</template>
<script>

import { getAllInvoices, getFiscalYears } from '@/api/invoices.api'
import { getSetting } from '@/api/settings.api'
import crudMixin from '@/api/crud'

export default {
    mixins: [crudMixin],
    data() {
        return {
            fyEnd: null,
            lastYear: null,
            firstYear: null,
            selectedYear: null,
            invoices: [],
        }
    },
    mounted() {
        this.getFinancialYear()
        this.getAllYears()
    },
    methods: {
        getAllYears() {
            this.call(
                getFiscalYears(),
                (res) => {
                    this.firstYear = res.data.years[0]
                    this.lastYear = res.data.years[1]
                    this.getFiscalYear(this.lastYear)
                }
            )
        },
        getFinancialYear() {
            this.call(
                getSetting('fiscal_date'),
                (res) => {
                    this.fyEnd = new Date(res.data.setting.value)
                }
            )
        },
        getFiscalYear(year) {
            this.selectedYear = year
            this.call(
                getAllInvoices(new URLSearchParams([
                    ['fiscal_year', year]]
                )),
                (res) => {
                    this.invoices.splice(0, this.invoices.length)
                    res.data.invoices.forEach(invoice => {
                        this.invoices.push(invoice)
                    })
                }
            )
        }
    },
    computed: {
        timePeriods() {
            var fyEnd = this.fyEnd
            if(fyEnd == null) {
                fyEnd = new Date()
            } else {
                fyEnd = new Date(this.fyEnd)
                fyEnd.setFullYear(this.selectedYear)
            }
            var fyStart = new Date(fyEnd)
            fyStart.setUTCFullYear(fyStart.getUTCFullYear() - 1)
            fyStart.setUTCDate(fyStart.getUTCDate() + 1)
            var periods = []
            var start = fyStart
            for(var i=0;i<12;i++) {
                var end = new Date(start)
                end.setUTCMonth(end.getUTCMonth() + 1)
                end.setUTCDate(end.getUTCDate() - 1)
                if(end < start) {
                    end.setUTCFullYear(end.getUTCFullYear() + 1)
                }
                periods.push([start, end])
                start = end
                start.setUTCDate(start.getUTCDate() + 1)
            }
            return periods
        },
        options() {
            var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            var periods = []
            
            this.timePeriods.forEach(period => {
                periods.push(period[0].toLocaleDateString('default', {timeZone: 'UTC'}) + ' TO ' + period[1].toLocaleDateString())
            })
            var start = this.timePeriods[0][0].getUTCDate();
            var startMonthIndex = this.timePeriods[0][0].getUTCMonth()
            var monthToDisplay = []
            while (monthToDisplay.length <= 12){
                monthToDisplay.push(months[startMonthIndex])
                startMonthIndex ++
                if (startMonthIndex % 12 == 0){
                    startMonthIndex = 0
                }
            }

            return {
                chart: {
                    id: 'paid-unpaid-chart',
                    stacked: true,
                    toolbar: {
                        show: true,
                    },
                    zoom: {
                        enabled: true
                    }
                },
                plotOptions: {
                bar: {
                horizontal: false,
                dataLabels: {
                    total: { enabled: true },
                },
                },
            },
                xaxis: {
                    //type: 'datetime',
                    categories: start != 1 ? periods : monthToDisplay
                },
                title: {
                    text: 'Revenue $' + this.totalRevenue,
                    align: 'center',
                    style: {
                        fontSize: '18px'
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return "$" + val.toLocaleString("en-US")
                    },
    
                },
            }
        },
        totalRevenue() {
            var total = 0
            this.invoices.forEach(invoice => {
                if(["INVOICED", "PAID"].includes(invoice.status)) {
                    total += parseFloat(invoice.subtotal)
                }
            })
            return total.toLocaleString("en-US")
        },
        series() {
            var paid = Array(12).fill(0);
            var unpaid = Array(12).fill(0);
            var periods = this.timePeriods
            this.invoices.forEach(invoice => {
                var invoiceDate = new Date(invoice.invoiced)
                for(var i=0;i<12;i++) {
                    if(invoiceDate >= periods[i][0] && invoiceDate <= periods[i][1]) {
                        if(invoice.status === 'INVOICED') {
                            unpaid[i] += parseFloat(invoice.subtotal)
                        } else if(invoice.status === 'PAID') {
                            paid[i] += parseFloat(invoice.subtotal)
                        }
                        break
                    }
                }
            })
            return [{
                name: 'Paid',
                data: paid.map(value => value.toFixed(0))
            }, {
                name: 'Unpaid',
                data: unpaid.map(value => value.toFixed(0))
            }]
        }
    }
}
</script>
<style scoped>
.parentClass{
    position: relative;
    z-index: 1;
}
.childClass {
    position: absolute;
    z-index: 2;
}
</style>
