<template>

<div>
    <b-table
        hover
        :items="formattedItems"
        :fields="computed_fields"
        small
        class="timesheet-table"
        foot-clone
        no-footer-sorting
    >
        <template #cell(clients)="row">
            <span :id="'popover-' + row.item.id">
                {{ row.item.consultant_role.project.client.company }}
            </span>
            <b-popover
                :target="'popover-' + row.item.id"
                placement="right"
                :title="row.item.consultant_role.project.client.company"
                triggers="hover focus"
                :content="row.item.comments"
            />
        </template>
        <template #cell(projects)="row">
            <span>{{ row.item.consultant_role.project.name }}</span>
        </template>
        <template #cell(users)="row">
            <span>{{ row.item.user.username }}</span>
        </template>
        <template #cell(roles)="row">
            <span>{{ row.item.consultant_role.name }}</span>
        </template>
        <template #foot(details)>
            <span class="text-danger">Total Hours</span>
        </template>
        <template #foot(time)>
            <span class="text-danger">{{ total_hours }}</span>
        </template>
        <template #foot()>
            <span />
        </template>
        <template #cell(invoice)="data">
            <span v-if="data.item.id in mapping">
                <b-link :href="'#/invoices/' + mapping[data.item.id][1]">{{ mapping[data.item.id][2] }} {{ mapping[data.item.id][3] }}</b-link>
            </span>
        </template>
        <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
            <slot :name="slotName" v-bind="scope"/>
        </template>
    </b-table>
</div>

</template>
<script>

export default {
    props: {
        items: Array,
        mapping: Object
    },
    data() {
        return {
            fields: [{
                key: 'date',
                label: 'Date',
                sortable: true,
            }, {
                key: 'clients',
                label: 'Clients',
                sortable: true,
            }, {
                key: 'projects',
                label: 'Projects',
                sortable: true,
            }, {
                label: 'Description',
                key: 'comments'
            }, {
                label: 'Users',
                key: 'users'
            }, {
                label: 'Roles',
                key: 'roles'
            }, {
                label: 'Hours',
                key: 'time'
            }, {
                label: 'Actions',
                key: 'actions'
            }]
        }
    },
    computed: {
        computed_fields: function() {
            if(Object.keys(this.mapping).length > 0) {
                return this.fields.concat([{
                    label: 'Invoice',
                    key: 'invoice'
                }])
            }
            return this.fields
        },
        pretty_date: function() {
            return this.timeView.toLocaleString('default', { month: 'long' }) + " " + this.timeView.getFullYear();
        },
        total_hours: function() {
            return  this.items.map(item => parseFloat(item.time))
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                .toFixed(2);
        },
        formattedItems() {
            return this.items.map(item => {
                if(item.invoiced) {
                    item._rowVariant = "secondary"
                } else {
                    item._rowVariant = ""
                }
                return item
            })
        }
    }
}
</script>
<style scoped>
.timesheet-table {
    font-size: 10pt;
}
</style>