<template>
  <div class="page-wrapper">

    <!-- Page header start -->
    <div class="page-header">

      <!-- Breadcrumb start -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
      </ol>
      <!-- Breadcrumb end -->
    </div>

    <div class="d-flex flex-wrap">
        <HoursWorkedChart class="mx-auto"/>
        <PaidUnpaidChart v-if="$store.getters.userRole == 'admin'" class="mx-auto"/>
    </div>

    <div v-if="$store.getters.userRole == 'admin'">
        <div>
            <h2>Timesheets</h2>
            <div>
                <b-card no-body>
                    <b-tabs pills card vertical lazy @changed="tabAdded" v-model="currentUser">
                        <b-tab title="Overview">
                            <b-row>
                                <b-col>User</b-col>
                                <b-col>This Month</b-col>
                                <b-col>Last Month</b-col>
                                <b-col>2 Months Ago</b-col>
                            </b-row>
                            <b-row v-for="user in working_users"
                                :key="user.id" @click="addUserToDisplay(user)">
                                <b-col>{{ user.username }}</b-col>
                                <b-col>{{ hours[0][user.id] }}</b-col>
                                <b-col>{{ hours[1][user.id] }}</b-col>
                                <b-col>{{ hours[2][user.id] }}</b-col>
                            </b-row>
                        </b-tab>
                        <b-tab v-for="(user, index) in displayed_users"
                            :key="index"
                            :title="user.username"
                        >
                            <TimesheetWidget :user="user" />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
        <h2>Time not yet billed</h2>
        <div>
            <b-card header-tag="header" footer-tag="footer">
                <b-table
                    sticky-header
                    hover
                    :items="outstandingItems"
                    :fields="outstandingFields"
                    small
                />
            </b-card>
        </div>
    </div>
  </div>
</template>
<script>

import { getAllUsers } from '@/api/users.api'
import { getConsultingTimesStats, getOutstandingTimes } from '@/api/consulting.api'
import TimesheetWidget from '@/components/TimesheetWidget';
import crudMixin from '@/api/crud'
import PaidUnpaidChart from '@/components/PaidUnpaidChart'
import HoursWorkedChart from '@/components/HoursWorkedChart'

export default {
    mixins: [crudMixin],
  name: 'Home',
  data () {
    return {
      users: [],
      hours: [],
      displayed_users: [],
      currentUser: 0,
      outstandingFields: [{
                key: 'user.username',
                label: 'User',
                sortable: true,
            }, {
                key: 'consultant_role.project.client.company',
                label: 'Client',
                sortable: true,
            }, {
                label: 'Project',
                key: 'consultant_role.project.name',
                sortable: true,
            }, {
                label: 'Hours',
                key: 'time',
                sortable: true,
            }, {
                label: 'Date',
                key: 'date',
                sortable: true,
            }],
      outstandingItems: [],
    }
  },
  computed: {
    working_users: function() {
        return this.users.filter(
            user => this.hours.flatMap(hour => Object.keys(hour).map(id => parseInt(id))).filter((v, i, a) => a.indexOf(v) === i).includes(user.id)
        )
    }
  },
  mounted() {
    if(this.$store.getters.userRole == 'admin') {
        this.getUsers()
    }
    this.call(
        getConsultingTimesStats(),
        (res) => {
            this.hours = res.data.times
        }
    ),
    this.call(
        getOutstandingTimes({}),
        (res) => {
            // Empty array, VueJS style
            console.log(res);
            this.outstandingItems.splice(0, this.outstandingItems.length)
            res.data.times.forEach(time => {
                if(time.rate != "0.00" ){
                    this.outstandingItems.push(time)
                }
            })
        }
    )
  },
  components: {
    TimesheetWidget,
    PaidUnpaidChart,
    HoursWorkedChart
  },
  methods: {
    getUsers() {
        this.call(
            getAllUsers(),
            (res) => {
                this.users = res.data.users
            }
        )
    },
    addUserToDisplay(user) {
        var index = this.displayed_users.indexOf(user)
        if(index == -1) {
            this.displayed_users.push(user);
        } else {
            this.currentUser = index + 1;
        }
    },
    tabAdded(currentTabs) {
        currentTabs[currentTabs.length - 1].activate()
    }
  }
}
</script>
