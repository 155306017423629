<template>

<b-card header-tag="header" footer-tag="footer">
    <template #header>
        <b-row class="text-center">
            <b-col><b-button @click="moveTimeWindow(-1)">Previous</b-button></b-col>
            <b-col><h4>{{ pretty_date }}</h4></b-col>
            <b-col><b-button @click="moveTimeWindow(1)">Next</b-button></b-col>
        </b-row>
    </template>
    <TimesheetTable :items="invoiced_items" :mapping="mapping">
        <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
            <slot :name="slotName" v-bind="scope"/>
        </template>
    </TimesheetTable>
</b-card>

</template>
<script>

import { getConsultingTimes, getOutstandingTimes } from '@/api/consulting.api'
import crudMixin from '@/api/crud'
import TimesheetTable from '@/components/TimesheetTable'
import { getTimesheetInvoiceMapping } from '@/api/users.api'

export default {
    mixins: [crudMixin],
    props: {
        user: Object
    },
    data() {
        return {
            timeView: new Date(),
            items: [],
            currentUser: null,
            mapping: {},
            outstandingTimes: []
        }
    },
    components: {
        TimesheetTable
    },
    computed: {
        pretty_date: function() {
            return this.timeView.toLocaleString('default', { month: 'long' }) + " " + this.timeView.getFullYear();
        },
        total_hours: function() {
            return  this.items.map(item => parseFloat(item.time))
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                .toFixed(2);
        },
        invoiced_items: function() {
            return this.items.map(item => {
                item.invoiced = true
                this.outstandingTimes.every(time => {
                    if (item.id == time.id) {
                        item.invoiced = false
                        // This in conjunction with every breaks the loop
                        return false
                    }
                    return true
                })
                return item
            })
        }
    },
    mounted() {
        if(this.user) {
            this.currentUser = this.user
            this.call(
                getTimesheetInvoiceMapping(this.currentUser.id),
                (response) => {
                    response.data.mapping.forEach(mapping => {
                        this.$set(this.mapping, mapping[0], mapping)
                    })
                }
            )
        }
        this.getHistory()
    },
    methods: {
        moveTimeWindow: function(increment) {
            // The next 3 lines are because of VueJS and reactivity
            // https://stackoverflow.com/questions/61987323/why-is-my-computed-property-for-a-date-not-reactive-on-changes
            var newDate = new Date(this.timeView)
            newDate.setMonth(newDate.getMonth() + increment)
            this.timeView = newDate

            this.getHistory()
        },
        getHistory: function() {
            var current = this.timeView
            var firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
            var lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);

            var params = new URLSearchParams([
                ['start', this.formatDate(firstDay)],
                ['end', this.formatDate(lastDay)]
            ])
            if(this.currentUser) {
                params.append("user_id", this.currentUser.id)
            } else {
                params.append("user_id", this.$store.getters.user_id)
            }
            this.call(
                getConsultingTimes(params),
                (res) => {
                    // Empty array, VueJS style
                    this.items.splice(0, this.items.length)
                    res.data.times.forEach(time => {
                        this.items.push(time)
                    })
                }
            )
            this.call(
                getOutstandingTimes(params),
                (res) => {
                    this.outstandingTimes.splice(0, this.outstandingTimes.length)
                    res.data.times.forEach(time => {
                        this.outstandingTimes.push(time)
                    })
                }
            )
        },
        formatDate(date) {
            // Split date by month, day and year
            var d = new Date(date)
            var month = '' + (d.getMonth() + 1)
            var day = '' + d.getDate()
            var year = d.getFullYear()

            // Padding with "0"
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            // Return date yyyy-mm-dd
            return [year, month, day].join('-');
        }
    }
}
</script>